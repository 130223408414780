import { useState } from "react"
import styled from "styled-components"

import { TFunctionResult } from "i18next"

import { colorsV2 } from "src/ui/colors"

import { VerticalTabButton } from "./VerticalTabButton"

const Box = styled.div`
  display: flex;
  width: 100%;
`

const SideBar = styled.div`
  display: grid;
  min-width: 22ch;
  height: max-content;
`

const MainContent = styled.div`
  width: 100%;
  flex: 1 1 auto;
  border-left: 1px solid ${colorsV2.divider};
  flex-grow: 1;
`

export interface IVerticalTabView {
  title: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  titleInterpolation?: any
  description?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  descriptionInterpolation?: any
  view: React.ReactNode
  icon?: React.ReactNode
}

function getActiveVerticalTab() {
  return 0
}

export function VerticalTabView({
  views,
  title,
}: {
  views: IVerticalTabView[]
  title?: React.ReactNode | TFunctionResult
}) {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    getActiveVerticalTab()
  )

  return (
    <Box>
      <SideBar>
        {title}
        {views.map((view, index) => {
          return (
            <VerticalTabButton
              key={index}
              icon={view.icon}
              title={view.title}
              infoText={view.description}
              onClick={() => setActiveTabIndex(index)}
              active={activeTabIndex === index}
            />
          )
        })}
      </SideBar>
      <MainContent>{views[activeTabIndex].view}</MainContent>
    </Box>
  )
}
