import styled from "styled-components"

import Button from "@material-ui/core/Button"

import { colorsV2 } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const StyledButton = styled(Button)`
  padding: 0;
  border-radius: 0;
  &:focus {
    outline: none;
  }
  &:hover {
    background: inherit;
  }
`

const Box = styled.div<{ $active?: boolean }>`
  text-align: left;
  width: 100%;
  cursor: pointer;
  padding: ${spacing.M};
  background: ${(props) => (props.$active ? colorsV2.neutral : "inherit")};
  display: flex;
  gap: ${spacing.S};

  border-right: 2px solid;
  border-right-color: ${(props) =>
    props.$active ? colorsV2.primary : "transparent"};

  /* Override MUI button stuff */
  line-height: 1.1;
  letter-spacing: normal;
`

const SectionText = styled.div`
  grid-area: text;
  align-self: center;
  display: grid;
  grid-row-gap: ${spacing.XS2};
`

export function VerticalTabButton({
  title,
  infoText,
  icon,
  active,
  onClick,
}: {
  title: React.ReactNode
  infoText?: string
  icon?: React.ReactNode
  active?: boolean
  onClick?: () => void
}) {
  return (
    <StyledButton>
      <Box $active={active} onClick={onClick}>
        {icon && <div>{icon}</div>}

        <SectionText>
          <MText variant="subtitle">{title}</MText>
          {!!infoText && <MText variant="bodyS">{infoText}</MText>}
        </SectionText>
      </Box>
    </StyledButton>
  )
}
